import React from 'react';
import AndroidMapComponent from './js/components/containers/android-container/AndroidMapComponent';
import './scss/main.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
 
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path="/wayfinding/*" element={<AndroidMapComponent />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
