import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMap } from '../../../context/MapContext';
import { fetchApis, initiateSdk, manageLayers, mapClicked, poiSelectedBySearch, changeNavigationDescription,highlightFloor ,postQRScanMetric} from './helper';
const AndroidMapComponent = ({ showPopup }) => {
  const { setMap } = useMap();
  const location = useLocation(); // Get the current location
  const [sdkLoaded, setSdkLoaded] = useState(false);

  useEffect(() => {
    const checkSdkAvailability = () => {
      if (window.PointrWebSDK) {
        setSdkLoaded(true);
        console.log("SDK Loaded");
      } else {
        setTimeout(checkSdkAvailability, 100); // Check again after 100ms
      }
    };

    checkSdkAvailability();
  }, []);


  // Function to create and show the loader
  function showLoader() {
    // Create and style the overlay element
    const overlay = document.createElement("div");
    overlay.id = "android-overlay"; // Set the id
    overlay.className = "android-overlay"; // Set the class name
    document.body.appendChild(overlay);

    // Create and style the loader element
    const loader = document.createElement("div");
    loader.id = "android-loader"; // Set the id
    loader.className = "android-loader"; // Set the class name
    document.body.appendChild(loader);

    // Display the loader and overlay
    if (loader && overlay) {
      overlay.style.display = "block";
      loader.style.display = "block";
    }
  }


  // Function to hide and remove the loader
  function hideLoader() {
    const loader = document.getElementById("android-loader");
    const overlayloader = document.getElementById("android-overlay");

    if (loader && overlayloader) {
      loader.style.display = "none";
      document.body.removeChild(loader);

      overlayloader.style.display = "none";
      document.body.removeChild(overlayloader);

    }
  }
  useEffect(() => {
    if (!sdkLoaded) return;
    let expressEnvironment = "spaces-us";

    let handleEnvironments = (expressEnvironment, isMobile) => {
      if (expressEnvironment === "spaces-us") {
        const elem = document.getElementsByClassName("pointr-loading-poster");
        if (elem.length > 0) {
          if (isMobile) {
            elem[0].src = `${window.env.BACKEND_API_URL}/wayfinding/img/splash-screen-android.png`

          } else {
            elem[0].src = `${window.env.BACKEND_API_URL}/wayfinding/img/loadingposter.png`

          }
        }
        const elem2 = document.getElementsByClassName("pointr-logo");
        if (elem2.length > 0) { // Check if the element exists
          for (let i = 0; i < elem2.length; i++) {
            elem2[i].style.display = "none"; // Hide the element
          }
        }
      }
    };
    function initiateData(clientDetails) {
      const width =
        window.innerWidth > 0 ? window.innerWidth : window.screen.width;
      const isMobile = width <= 480;

      handleEnvironments(expressEnvironment, isMobile);

      const pointrWeb = initiateSdk(clientDetails.host, clientDetails.clientKey, clientDetails.licenseKey)
      const uiController = pointrWeb.getUiController()
      const mapViewController = uiController.getMapViewController()
      const mapView = mapViewController.getView()
      const navigationViewController = uiController.getNavigationViewController()
      const searchViewController = uiController.getSearchViewController()
      const poiCardViewController = uiController.getPoiCardsViewController()

      const searchViewEvents = searchViewController.getView().events
      const mapEvents = mapView.events
      const poiCardViewEvents = poiCardViewController.getView().events;
      const navigationViewEvents = navigationViewController.getView().events;



      const levelChangeCallback = (newLevel) => {
        console.log("***Map level changed to ", newLevel);
        manageLayers(mapView, newLevel)
      };

      const mapClickedCallback = (features) => {
        console.log("***Map level changed to ", features);
        mapClicked(mapView, mapViewController, pointrWeb, navigationViewController, features)
      };


      const searchPoiSelected = (poiFid) => {
        console.log("***Map level changed to ", poiFid);
        poiSelectedBySearch(poiFid, mapViewController, mapView)
        // mapClicked(mapView, mapViewController, pointrWeb, navigationViewController, features)
      };


      const navigationViewShown = (data) => {
        changeNavigationDescription()
      }

      const mapReadyCallback = (data) => {
        mapView.on(mapEvents.mapReady, async () => {
          postQRScanMetric(pointrWeb,clientDetails);
          if (clientDetails.poiId) {
            // Show the loader
            showLoader();
            setTimeout(async () => {
              var myStartPoi = await pointrWeb.getPoiManager().get(clientDetails.poiId);
              if (myStartPoi)
                pointrWeb.highlight(myStartPoi)

              hideLoader(uiController);
            }, 10000);

            console.log("***Map Poi changed to ", data);

          } else {
            hideLoader(uiController);

            switch (clientDetails.actionType) {
              case "site": {
                console.log("***Action type is 'site' ");
                mapViewController.updateCurrentSite(clientDetails.locationId);
              }
                break;
              case "building": {
                console.log("***Action type is 'building' ");
                mapViewController.updateCurrentBuilding(clientDetails.locationId);
              }
                break;
              case "floor": {
                console.log("***Action type is 'floor' & 'lvlIndex' is ", clientDetails.lvlIndex);
                highlightFloor(uiController, mapView, clientDetails.lvlIndex)
                 
              }
                break;

            }
          }

        })

      };
      mapView.on(mapEvents.levelChanged, levelChangeCallback)
      pointrWeb.on(pointrWeb.events.dataLoaded, mapReadyCallback)
      mapView.on(mapEvents.mapClicked, mapClickedCallback)
      searchViewController.getView().on(searchViewEvents.poiSelected, searchPoiSelected)
      poiCardViewController.getView().on(poiCardViewEvents.poiSelected, searchPoiSelected)
      navigationViewController.getView().on(navigationViewEvents.viewShown, navigationViewShown)
      navigationViewController.getView().on(navigationViewEvents.startSelected, navigationViewShown)
      navigationViewController.getView().on(navigationViewEvents.destinationSelected, navigationViewShown)

    

    }



    const fetchData = async () => {
      // Get the current path and extract the last segment
      const path = location.pathname;
      const shortCode = path.substring(path.lastIndexOf('/') + 1);


      try {
        let apiResponse = fetchApis(shortCode)
          .then(result => {
            if (result.success) {
              console.log('Data fetched successfully:', result.data);
              result.data.shortCode=shortCode
              initiateData(result.data)
            } else {

              console.log(result);
              window.alert("Invalid shortCode provided/ shortcode expired");
              throw new Error('Network response was not ok');

            }
          });
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };

    fetchData();

  }, [sdkLoaded, location, setMap]);

  return (
    <div id="pointr-container" className="pointr-container">
    </div>
  );
};

export default AndroidMapComponent;
